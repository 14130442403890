import React from 'react'
import IntegrationTemplate from '../../components/Integrations/IntegrationTemplate'

import weeblyLogo from '../../images/integration-logo-images/weebly.png'

const Weebly = () => {
    return (
        <>
            <IntegrationTemplate 

                metaTitle="Integrate Optinly with Weebly"
                metaDescription="Optinly is the smart pop-up builder for your Weebly store. With Optinly, you can seamlessly attract leads to grow your email list."

                otherProductLogo={weeblyLogo} //PLEASE IMPORT THE LOGO HERE LIKE THIS

                integrationApp="Weebly"
                homeTitle="Grow Your Email List with Weebly Popups"
                homeDescription="Integrate Optinly with your Weebly store. Ideal lead generation tool with visitor engaging popups to grow your email list. Increase Sales with smart popups. "

                docsTitle="Increase your Weebly store email list "
                docsDescription="Best Weebly popup app. A complete package to rapidly grow your email list, leads and subscribers. Select from pre-made popups or custom popup in your Weebly store. "

                featruesDocsSection1="Integeration and Customization "
                featruesDocsTitle1="Seamless Integeration and Advance Customization Options"
                featruesDocsDescription1="Customize popups based on user behavioural pattern. Design or development skill not required to customize popup. Weebly email popup through simple integeration. "

                featruesDocsSection2="Installation and Signup"
                featruesDocsTitle2 ="Weebly Popups To Improve Store Performance"
                featruesDocsDescription2="Hassle-free signup, download, and installation. Best Weebly popup form to deliver customer-friendly popup. Select the best email popup for Weebly from numerous existing templates."

                featruesDocsSection3="Achieve Marketing Goals "
                featruesDocsTitle3 ="One-stop Solution for Weebly Popup building"
                featruesDocsDescription3="Everything you need to create user engaging popups in one place. Advacned dashboard, simple integeration, regular update of pre-made templates and a clear user interface."

                featruesDocsSection4="Detailed Dashboard Analytics"
                featruesDocsTitle4 ="All-in-one Dashboard"
                featruesDocsDescription4="Know how your Weebly Popup is performing. Manage campaigns, make changes, and more with a powerful dashboard. Simple to navigate yet highly accurate analytics available."

                FAQQuestion1="Does Optinly offer Weebly email popup?  "
                FAQAnswer1="Optinly offers multiple redaymade Weebly email popups. Also, it provides options to easily customize the popup depending upon the user. Weebly email popups are popups used in Weebly websites for collecting quality customer email address. "

                FAQQuestion2="How many types of Weebly popup form is available in Optinly?"
                FAQAnswer2="Optinly offers 6+ types of unique popup forms for Weebly. Each one is engaging and grabs audience attentation without any delay. "

                FAQQuestion3="Is there a free pricing plan available in Optinly for Weebly stores? " 
                FAQAnswer3="Optinly have a free pricing plan that works on Weebly stores. Feel free to check out the entire pricing structure in Optinly pricing page.  "

                FAQQuestion4="Is Weebly exit popup available in Optinly? "
                FAQAnswer4="Yes, Optinly have customer engaging exit popup templates. You can personalize the template or made use of the pre-made one. "
            />
        </>
    )
}

export default Weebly
